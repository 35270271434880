/**
 * @generated SignedSource<<a07f6c4605a2836fd2275bb2c6330238>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMergedSbAndSponsoredItems_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly item: {
        readonly serviceId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"SbMobileSearchResultContainer_item" | "SbRespSearchResultContainer_item">;
      } | null;
    } | null;
  } | null> | null;
  readonly sponsored: {
    readonly items: ReadonlyArray<{
      readonly serviceId: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SbMobileSearchResultContainer_item" | "SbRespSearchResultContainer_item">;
    } | null> | null;
    readonly metadata: ReadonlyArray<{
      readonly clickTrackerLink: string | null;
      readonly impressionTrackerLink: string | null;
      readonly itemId: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "useMergedSbAndSponsoredItems_itemSearch";
};
export type useMergedSbAndSponsoredItems_itemSearch$key = {
  readonly " $data"?: useMergedSbAndSponsoredItems_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMergedSbAndSponsoredItems_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serviceId",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "SbRespSearchResultContainer_item"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "SbMobileSearchResultContainer_item"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMergedSbAndSponsoredItems_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchSponsored",
      "kind": "LinkedField",
      "name": "sponsored",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Item",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchSponsoredMetadata",
          "kind": "LinkedField",
          "name": "metadata",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "impressionTrackerLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clickTrackerLink",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchResult",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "054b20a5d7ccd08cd72520ce9a7d9c47";

export default node;
