/**
 * @generated SignedSource<<19164aed5c734338c9e9e9bd95edf22b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ecommerceTracking_itemSearch$data = {
  readonly pageType: string | null;
  readonly searchRequestId: string | null;
  readonly searchTerm: string | null;
  readonly sponsored: {
    readonly maxAvailable: number | null;
    readonly metadata: ReadonlyArray<{
      readonly clickTrackerLink: string | null;
      readonly impressionTrackerLink: string | null;
      readonly itemId: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ecommerceTracking_itemSearch";
};
export type ecommerceTracking_itemSearch$key = {
  readonly " $data"?: ecommerceTracking_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTracking_itemSearch">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ecommerceTracking_itemSearch"
};

(node as any).hash = "f512e01ea64a6ffbcd530c0f47ee25bc";

export default node;
